<template>
  <CForm @submit.prevent="recover">
    <h1 class="mb-3 text-center">
      Восстановление пароля
    </h1>
    <CAlert
      v-for="error in recoverForm.errors.global"
      :key="error"
      color="danger"
      close-button
    >
      {{ error }}
    </CAlert>
    <CInput
      v-model="recoverForm.data.email"
      placeholder="E-mail"
      required
      type="email"
    >
      <template #prepend-content>
        <CIcon name="cilUser" />
      </template>
    </CInput>
    <CRow align-vertical="center">
      <CCol col="auto">
        <loading-button :loading="recoverForm.inProcessing" text="Восстановить" loading-text="Загрузка" />
      </CCol>
      <CCol>
        <CLink :to="{ name: 'login' }">
          Войти
        </CLink>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import AuthService from '@/services/auth-service';
import LoadingButton from '@/components/loading-button';
import Swal from 'sweetalert2';

export default {
  name: 'Recover',
  components: {
    LoadingButton
  },
  data() {
    return {
      recoverForm: {
        data: {
          email: null,
        },
        errors: {
          data: {},
          global: [],
        },
        inProcessing: false,
      },
    };
  },

  methods: {
    async recover() {
      this.recoverForm.errors.global = [];

      this.recoverForm.inProcessing = true;

      try {
        await AuthService.restorePassword(this.recoverForm.data);
        await Swal.fire(
          'Успешно',
          'Проверьте почту для сброса пароля',
          'success',
        );
        this.$router.push({name: 'login'});
      } catch (e) {
        if (e.response) {
          if (e.response.status === 404) {
            this.recoverForm.errors.global.push(
              'Пользователь не найден'
            );
          }
        }
      } finally {
        this.recoverForm.inProcessing = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
